var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "!!range-webchat-prod$$",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api-connect.digitalrange.com"
            // API_URL: "https://dev-api-connect.digitalrange.com"
            // API_URL: "https://range-connect.appnofy.com"
        },
        DEVELOPMENT: {
            // API_URL: "http://54.215.117.148:3010"
            API_URL: "https://dev-api-connect.digitalrange.com",
            // API_URL: "http://localhost:3010",    
            WEBCHAT_URL_DOMAIN: "https://widget.digitalrange.com/",
            // BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
        },
        STAGING: {
            API_URL: "https://stg-api.com"
        },
        PRODUCTION: {
            API_URL: "https://api-connect.digitalrange.com",
            WEBCHAT_URL_DOMAIN: "https://widget.digitalrange.com/",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
            // API_URL: "https://ed79-119-160-2-14.ngrok.io",
            // API_URL: "https://range-connect.appnofy.com"
        }
    }
};
Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;

